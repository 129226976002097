import React from 'react';

import { Button, Form, Grid, Input, theme, Typography } from 'antd';
import { notification } from 'antd';

import './style.css';
import { supabase } from './supabaseClient';

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

export default function Auth() {
  const { token } = useToken();
  const screens = useBreakpoint();

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    const email = values.email;
    const { error } = await supabase.auth.signInWithOtp({ email });

    if (error) {
      // alert(error.error_description || error.message);
      openNotificationWithIcon('error', error.error_description || error.message);
    } else {
      // alert('Check your email for the login link!');
      openNotificationWithIcon('info', 'Check your email for the login link.');
    }
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message: type,
      description: message
    });
  };

  const styles = {
    container: {
      margin: '0 auto',
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
      width: '380px',
      textAlign: 'center',
      borderRadius: 8,
      border: '1px dashed rgba(5, 5, 5, 0.06)'
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: 'center',
      width: '100%'
    },
    forgotPassword: {
      float: 'right'
    },
    header: {
      marginBottom: token.marginXL
    },
    section: {
      alignItems: 'center',
      backgroundColor: token.colorBgContainer,
      display: 'flex',
      height: screens.sm ? '50vh' : 'auto',
      padding: screens.md ? `${token.sizeXXL}px 0px` : '0px'
    },
    text: {
      color: token.colorTextSecondary
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
    }
  };

  return (
    <>
      {contextHolder}
      <section style={styles.section}>
        <div style={styles.container}>
          <div style={styles.header}>
            <Title style={styles.title}>convert.com.de</Title>
            <Text style={styles.text}>Get a secure login link sent to your mail box.</Text>
          </div>
          <Form
            name="normal_login"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Looks like your email address might have a typo.'
                }
              ]}
            >
              <Input size="large" placeholder="Please type your email address..." />
            </Form.Item>
            <Form.Item style={{ marginBottom: '0px' }}>
              <Button block="true" type="default" htmlType="submit">
                Continue &#x2192;
              </Button>
              <div style={styles.footer}></div>
            </Form.Item>
          </Form>
        </div>
      </section>
    </>
  );
}
